import {
  mapActions, mapGetters,
} from 'vuex';
import _ from 'lodash';
import { mask } from 'vue-the-mask';
import moment from 'moment';

import {
  Sidebar,
  InterestCard,
  Loader,
} from '@/components';

import isServer from '@/utils/isServer';

if (!isServer) {
  const { required, email, length } = require('vee-validate/dist/rules');
  const { extend } = require('vee-validate');

  extend('required', {
    ...required,
    message: 'Dit veld is vereist.',
  });

  extend('length', {
    ...length,
    message: 'Dit is geen correct licentienummer.',
  });

  extend('email', {
    ...email,
    message: 'Een geldig e-mailadres is vereist.',
  });

  extend('licenseNumber', {
    validate: (licenseNumber) => {
      var firstNumbers = parseInt(licenseNumber.substr(0, 4), 10);
      var nextYear = new Date().getFullYear() + 1;
      if (firstNumbers > 1900 && firstNumbers <= nextYear) {
        return true;
      }
      return false;
    },
    message: 'Dit is geen correct licentienummer.',
  });

  extend('viable-date', {
    validate: (date) => {
      var dateParts = date.split('/');
      var day = dateParts[0];
      var month = dateParts[1] - 1;
      var year = dateParts[2];
      var inputDate = new Date(year, month, day);
      var dateIsInvalid = Date.parse(inputDate).toString().toLowerCase() === 'nan';
      if (dateIsInvalid) return false;
      return true;
    },
    message: 'Gelieve een geldige datum in te voeren.',
  });

  extend('max-date', {
    validate: (date) => {
      var dateParts = date.split('/');
      var day = dateParts[0];
      var month = dateParts[1] - 1;
      var year = dateParts[2];
      var inputDate = new Date(year, month, day);
      var lastPossibleDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
      if (inputDate > lastPossibleDate) {
        return false;
      }
      return true;
    },
    message: 'Je moet minstens 1 jaar oud zijn.',
  });

  extend('max-age', {
    validate: (date, { maxAge }) => {
      var dateParts = date.split('/');
      var day = dateParts[0];
      var month = dateParts[1] - 1;
      var year = dateParts[2];
      var inputDate = new Date(year, month, day);
      if (moment().diff(moment(inputDate), 'years') > maxAge) {
        return false;
      }
      return true;
    },
    params: ['maxAge'],
    message: 'Je kan maximum 150 jaar oud zijn.',
  });

  var ImageUpload = require('vue-image-crop-upload').default;
}

export default {
  metaInfo: {
    title: 'Edit profile page',
  },
  name: 'EditProfile',
  directives: {
    mask,
  },
  components: {
    [Sidebar.name]: Sidebar,
    [InterestCard.name]: InterestCard,
    [Loader.name]: Loader,
    ImageUpload,
  },
  props: {},
  data: () => ({
    userObj: {
      email: '',
      firstname: '',
      lastname: '',
      disciplines: [],
      image: {},
      phone: '',
      birthDate: '',
      sex: '',
      addressStreet: '',
      addressNumber: '',
      addressCity: '',
      addressPostalcode: '',
      licenseNumber: '',
    },
    toAge: 150,
    genderArray: [{ name: 'Man', value: 'M' }, { name: 'Vrouw', value: 'V' }, { name: 'Andere', value: 'X' }],
    interestError: '',
    imageObj: {},
    show: false,
    disciplines: [],
    error: false,
  }),
  computed: {
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
    ...mapGetters('account', {
      currentUser: 'getCurrentUser',
      isLoadingProfile: 'getLoadingState',
    }),
  },
  methods: {
    ...mapActions('discipline', {
      initialiseDisciplineList: 'FETCH_DATA_DISCIPLINE_LIST',
    }),
    ...mapActions('account', {
      editAction: 'EDIT_USER',
      initialiseUser: 'FETCH_DATA_EDIT_USER',
    }),
    save() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            const errorKeys = Object.keys(this.$refs.form.errors);
            const errors = errorKeys.filter((e) => this.$refs.form.errors[e].length > 0);

            this.$refs.form.refs[errors[0]].$el.scrollIntoView();
            window.scrollBy(0, -75);
          }, 100);
        } else if (this.userObj.disciplines.length <= 0) {
          this.interestError = 'Minstens 1 interesse vereist.';
        } else {
          delete this.userObj.roles;
          this.editAction(this.userObj)
            .then(() => {
              this.$router.push({ name: 'profile' });
              this.$toasted.show('Profiel is succesvol aangepast.', { type: 'success' });
            })
            .catch((error) => {
              console.log(error);
              this.$toasted.show(...error.response.data);
            });
        }
      });
    },
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl) {
      fetch(imgDataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'File name', { type: 'image/png' });
          this.userObj.profilePicture = file;
          this.userObj.hasPicture = true;
        })
        .then(() => {
          this.createImage(this.userObj.profilePicture);
          this.toggleShow();
        });
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageObj = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    addInterestId(id) {
      this.interestError = '';
      this.userObj.disciplines.push(id);
    },
    removeInterestId(id) {
      const index = this.userObj.disciplines.indexOf(id);
      this.userObj.disciplines.splice(index, 1);
    },
    disciplineIsActive(discipline) {
      return this.userObj.disciplines.includes(discipline.id);
    },
  },
  created() {
    this.initialiseDisciplineList().then(() => {
      this.disciplines = _.cloneDeep(this.disciplineList);
      if (this.currentUser) {
        this.userObj = _.cloneDeep(this.currentUser);
        if (this.currentUser.sex) this.userObj.sex = this.genderArray.find((g) => g.value === this.currentUser.sex);
        var birth = this.$options.filters.formatDate(this.currentUser.birthDate);
        if (birth === this.$options.filters.formatDate(new Date('0001-01-01'))) {
          this.userObj.birthDate = '';
        } else this.userObj.birthDate = birth;
        this.imageObj = _.cloneDeep(this.currentUser.profilePicture);
      } else {
        this.initialiseUser().then(() => {
          this.userObj = _.cloneDeep(this.currentUser);
          if (this.currentUser.sex) this.userObj.sex = this.genderArray.find((g) => g.value === this.currentUser.sex);
          var birthday = this.$options.filters.formatDate(this.currentUser.birthDate);
          if (birthday === this.$options.filters.formatDate(new Date('0001-01-01'))) {
            this.userObj.birthDate = '';
          } else this.userObj.birthDate = birthday;
        }).catch((error) => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' });
          } else {
            this.$toasted.show(...error.response.data);
          }
        });
        this.userObj = _.cloneDeep(this.currentUser);
        this.imageObj = this.currentUser.profilePicture;
      }
    });
  },
};
