var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-view l-container--full-height l-container--large p-profile"},[_c('Sidebar',{staticClass:"o-sidebar o-sidebar--with-img"},[_c('img',{staticClass:"o-sidebar__img",attrs:{"src":require("@/assets/images/generic/afbeelding-evenement-4x.jpg"),"alt":"Open"}})]),_c('div',{staticClass:"o-page o-page--white"},[_c('div',{staticClass:"p-profile__content"},[_c('h1',{staticClass:"p-profile__title"},[_vm._v("Profiel")]),(_vm.userObj.firstname)?_c('div',[_c('ValidationObserver',{ref:"form",attrs:{"slim":""}},[_c('form',{staticClass:"p-profile__form",attrs:{"id":"profileForm"},on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('ImageUpload',{attrs:{"field":"img","width":150,"height":150,"lang-type":"nl","img-format":"png","noSquare":true,"noCircle":true,"value":_vm.show},on:{"crop-success":_vm.cropSuccess,"update:value":function($event){_vm.show=$event}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('div',[_c('div',[((!_vm.error && _vm.userObj.hasPicture))?_c('img',{staticClass:"p-profile__profile-img",attrs:{"src":_vm.imageObj || require('@/assets/images/profile/empty-profile-picture.png'),"alt":"afbeelding profiel"},on:{"click":function($event){return _vm.toggleShow()},"error":function($event){_vm.error=true}}}):_c('img',{staticClass:"a-input__image",attrs:{"src":require('@/assets/images/profile/empty-profile-picture.png'),"alt":"afbeelding profiel"},on:{"click":function($event){return _vm.toggleShow()}}})])]),_c('div',{staticClass:"p-profile__name-wrapper"},[_c('ValidationProvider',{staticClass:"a-input__label",attrs:{"rules":"required","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Voornaam "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.userObj.firstname),expression:"userObj.firstname",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"text","name":"firstname","placeholder":"Voornaam"},domProps:{"value":(_vm.userObj.firstname)},on:{"change":function($event){return _vm.$set(_vm.userObj, "firstname", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2078041682)}),_c('ValidationProvider',{staticClass:"a-input__label p-profile__form-label--surname",attrs:{"rules":"required","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Achternaam "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.userObj.lastname),expression:"userObj.lastname",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"text","name":"lastname","placeholder":"Achternaam"},domProps:{"value":(_vm.userObj.lastname)},on:{"change":function($event){return _vm.$set(_vm.userObj, "lastname", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3239323778)})],1),_c('ValidationProvider',{staticClass:"a-input__label",attrs:{"rules":"required|email","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" E-Mail "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.userObj.email),expression:"userObj.email",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"email","name":"email","placeholder":"E-mail"},domProps:{"value":(_vm.userObj.email)},on:{"change":function($event){return _vm.$set(_vm.userObj, "email", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1421458834)}),_c('div',{staticClass:"a-input__label"},[_c('label',{staticClass:"a-input__label--no-margin"},[_vm._v("Geslacht")]),_c('multiselect',{attrs:{"track-by":"value","label":"name","placeholder":"Selecteer een geslacht","options":_vm.genderArray,"show-labels":false,"allow-empty":false,"close-on-select":true},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.name))]}}],null,false,2705703343),model:{value:(_vm.userObj.sex),callback:function ($$v) {_vm.$set(_vm.userObj, "sex", $$v)},expression:"userObj.sex"}})],1),_c('div',{staticClass:"p-profile__name-wrapper"},[_c('ValidationProvider',{staticClass:"a-input__label",attrs:{"tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Straat "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.userObj.addressStreet),expression:"userObj.addressStreet",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"text","name":"addressStreet","placeholder":"Straat"},domProps:{"value":(_vm.userObj.addressStreet)},on:{"change":function($event){return _vm.$set(_vm.userObj, "addressStreet", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1552408376)}),_c('ValidationProvider',{staticClass:"a-input__label p-profile__form-label--surname",attrs:{"tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Nr "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.userObj.addressNumber),expression:"userObj.addressNumber",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"text","name":"number","placeholder":"Nr"},domProps:{"value":(_vm.userObj.addressNumber)},on:{"change":function($event){return _vm.$set(_vm.userObj, "addressNumber", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1612612652)})],1),_c('div',{staticClass:"p-profile__name-wrapper"},[_c('ValidationProvider',{staticClass:"a-input__label",attrs:{"tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Postcode "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.userObj.addressPostalcode),expression:"userObj.addressPostalcode",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"text","name":"postalcode","placeholder":"Postcode"},domProps:{"value":(_vm.userObj.addressPostalcode)},on:{"change":function($event){return _vm.$set(_vm.userObj, "addressPostalcode", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,653022199)}),_c('ValidationProvider',{staticClass:"a-input__label p-profile__form-label--surname",attrs:{"tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Plaats "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.userObj.addressCity),expression:"userObj.addressCity",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"text","name":"place","placeholder":"Plaats"},domProps:{"value":(_vm.userObj.addressCity)},on:{"change":function($event){return _vm.$set(_vm.userObj, "addressCity", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1713295956)})],1),_c('div',{staticClass:"p-profile__name-wrapper"},[_c('ValidationProvider',{staticClass:"a-input__label p-profile__form-label--firstname",attrs:{"tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Telefoonnummer "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.userObj.phone),expression:"userObj.phone",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"text","name":"Telefoonnummer","placeholder":"Telefoonnummer"},domProps:{"value":(_vm.userObj.phone)},on:{"change":function($event){return _vm.$set(_vm.userObj, "phone", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,578235603)}),_c('ValidationProvider',{staticClass:"a-input__label p-event-register__form-label--right p-profile__form-label--surname",attrs:{"rules":("max-date|viable-date|max-age:" + _vm.toAge)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Geboortedatum "),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"model",rawName:"v-model",value:(_vm.userObj.birthDate),expression:"userObj.birthDate"}],staticClass:"a-input",attrs:{"type":"text","input-id":"birthDay","placeholder":"DD/MM/YYYY"},domProps:{"value":(_vm.userObj.birthDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userObj, "birthDate", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3759407979)})],1),_c('ValidationProvider',{ref:"licenseNumber",staticClass:"a-input__label",attrs:{"mode":"eager","rules":"length:10|licenseNumber","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" WWSV Licentienummer "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.userObj.licenseNumber),expression:"userObj.licenseNumber",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"text","name":"Licentienummer","placeholder":"Licentienummer"},domProps:{"value":(_vm.userObj.licenseNumber)},on:{"change":function($event){return _vm.$set(_vm.userObj, "licenseNumber", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,262676659)}),_c('p',{staticClass:"p-profile__interest-title u-margin-top-lg"},[_vm._v("Interesse in")]),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(_vm.interestError))]),_c('div',{staticClass:"p-profile__interest-card-container"},_vm._l((_vm.disciplines),function(discipline){return _c('InterestCard',{key:discipline.name,attrs:{"isActive":_vm.disciplineIsActive(discipline),"interest":discipline},on:{"addInterestId":_vm.addInterestId,"removeInterestId":_vm.removeInterestId}})}),1),(_vm.isLoadingProfile)?_c('Loader',{attrs:{"isLoading":_vm.isLoadingProfile,"color":'spinner--green'}}):_vm._e(),_c('button',{staticClass:"a-button a-button--primary",class:{ loading: _vm.isLoadingProfile },attrs:{"disabled":_vm.isLoadingProfile}},[_vm._v(" Opslaan ")])],1)])],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }