import { render, staticRenderFns } from "./EditProfile.vue?vue&type=template&id=8917095a&"
import script from "./EditProfile.controller.js?vue&type=script&lang=js&"
export * from "./EditProfile.controller.js?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./EditProfile.styles.scss?vue&type=style&index=0&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "c50fd5d2"
  
)

export default component.exports